import * as React from 'react'
import { Notice } from 'src/design-system'
import { TReadme } from '../../types/entities'
import { ReadmeNoticeVm } from './readme-notice-vm'
import { useRailsContext } from 'components/rails-context'

export type ReadmeNoticeProps = {
  readme: TReadme
}

export const ReadmeNotice: React.VFC<ReadmeNoticeProps> = ({ readme }) => {
  const { org, currentUser } = useRailsContext()

  if (!org || !currentUser) return null

  const vm = new ReadmeNoticeVm(readme)

  return (
    <Notice className="mb-8" variant={vm.variant}>
      {vm.message}
    </Notice>
  )
}
