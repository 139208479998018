import { VFC } from 'react'
import { ReadmeNotice } from 'components/readme/readme-notice'
import { forRails } from 'components/for-rails'
import { ReadmeHeader } from 'components/readme/readme-header'
import { registerComponents } from '../src/utils/react/registry'

registerComponents({
  ReadmeNotice: forRails(ReadmeNotice),
  ReadmeHeader: forRails(ReadmeHeader),
})
