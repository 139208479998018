import { Readme } from '../../models/readme'
import { TReadme } from '../../types/entities'

export class ReadmeHeaderVm {
  constructor(private readme: TReadme) {}

  private get readmeModel() {
    return new Readme(this.readme)
  }

  get lastEditor() {
    return this.readmeModel.lastEditor
  }

  get lastEditDate() {
    return this.readmeModel.lastEditedDate
  }

  get published() {
    return this.readmeModel.published
  }

  get tooltipContent() {
    return this.published ? 'Visible to everyone' : 'Visible to admins'
  }
}
