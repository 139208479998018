import { observer } from 'mobx-react-lite'
import { PencilSimple } from '@phosphor-icons/react'
import { UserAvatar } from 'src/design-system'
import * as React from 'react'
import { ReadmeHeaderVm } from './readme-header-vm'
import { TReadme } from '../../types/entities'
import { useRailsContext } from 'components/rails-context'
import cn from 'classnames'

export type ReadmeHeaderProps = {
  editPath: string
  readme: TReadme
  showHeader?: boolean
} & Omit<React.ComponentPropsWithoutRef<'header'>, 'children'>

/**
 * Readme header, shown on the readme show page. Hidden for non org-admins.
 */
export const ReadmeHeader: React.VFC<ReadmeHeaderProps> = observer(
  ({ readme, editPath, showHeader = false, ...restProps }) => {
    const { org, currentUser } = useRailsContext()

    if (!org || !currentUser) return null

    const vm = new ReadmeHeaderVm(readme)

    if (!showHeader) return null

    return (
      <header
        className="flex flex-col items-center sm:flex-row justify-between py-3 md:py-6 lg:px-8 gap-4"
        {...restProps}
      >
        {vm.lastEditor && (
          <div className="flex flex-row gap-x-2">
            <UserAvatar size="xs" user={vm.lastEditor} />
            <p className="text-gray-600 text-sm mb-0">
              Last edit: {vm.lastEditDate}
            </p>
          </div>
        )}
        <div
          className={cn(
            'flex flex-row gap-x-5 sm:ml-auto',
            !vm.lastEditor && 'ml-auto'
          )}
        >
          <a
            href={editPath}
            className="flex flex-row gap-x-1 items-center text-green-700"
            data-turbo-frame="content"
            data-turbo-action="advance"
          >
            <PencilSimple aria-hidden weight="bold" />
            <span>Edit</span>
          </a>
        </div>
      </header>
    )
  }
)
