import { Readme } from '../../models/readme'
import type { TReadme } from '../../types/entities'

export class ReadmeNoticeVm {
  readmeModel: Readme

  constructor(readme: TReadme) {
    this.readmeModel = new Readme(readme)
  }

  get variant(): 'warning' | 'info' {
    return this.readmeModel.published ? 'warning' : 'info'
  }

  get message(): string {
    return this.readmeModel.published
      ? 'Readme currently visible to everyone in the org. Unpublish to share with just admins.'
      : 'Readme currently only visible to admins. Publish to share with everyone in the org.'
  }
}
