import type { TReadme } from '../types/entities'
import { formatRelative } from 'date-fns'

export class Readme {
  constructor(private readme: TReadme) {}

  get lastEditor() {
    return this.readme.lastEditor
      ? {
          id: this.readme.lastEditor.id,
          fname: this.readme.lastEditor.fname,
          lname: this.readme.lastEditor.lname,
          avatarUrl: this.readme.lastEditor.avatarUrl,
          avatarAltText: `Avatar for ${this.readme.lastEditor.name}`,
          state: this.readme.lastEditor.state,
        }
      : null
  }

  get lastEditedDate() {
    return formatRelative(new Date(this.readme.updatedAt), new Date())
  }

  get published() {
    return this.readme.visibility === 'published'
  }
}
